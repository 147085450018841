import React, { Component } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Route } from 'react-router-dom';
import { Layout } from "./components/Layout/Layout";
import { Home } from "./components/Home";
import { Assets } from "./components/Assets";
import { HomeScreen } from "./components/HomeScreen";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
} from "@fluentui/react-components";
import { MyDayAPIHelper } from "./infrastructure/MyDayAPIHelper";
import { AuthContext } from "./infrastructure/AuthContext";
import { PrivateRoute } from "./infrastructure/PrivateRoute";
import { initializeIcons } from "@fluentui/font-icons-mdl2";

import "leaflet/dist/leaflet.css";
import "./custom.css";
import { AuthStart } from "./components/AuthStart";

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);

    this.state = {
      theme: this.getTheme("default"),
      isAuthenticated: MyDayAPIHelper.IsAuthenticated(),
      tenantId: MyDayAPIHelper.TennatId(),
    };

    this.refreshApplicationState = this.refreshApplicationState.bind(this);

    try {
      microsoftTeams.registerOnThemeChangeHandler(this.updateTheme.bind(this));
      microsoftTeams.getContext((context) => {
        this.updateTheme(context.theme);
      });
    } catch (e) {
      console.log("Teams app not available");
    }

    initializeIcons();
  }

  async componentDidMount() {
    await this.refreshApplicationState();
  }

  async refreshApplicationState() {
    this.setState({
      isAuthenticated: MyDayAPIHelper.IsAuthenticated(),
      tenantId: MyDayAPIHelper.TennatId(),
    });
  }

  render() {
    const value = {
      isAuthenticated: this.state.isAuthenticated,
      refresh: this.refreshApplicationState,
      tenantId: this.state.tenantId,
    };

    return (
      <AuthContext.Provider value={value}>
        <FluentProvider theme={this.state.theme}>
                <Layout>
                    <Route exact path="/auth-start" component={AuthStart} />
                    <PrivateRoute exact path="/" component={HomeScreen} />
                    <PrivateRoute exact path="/home" component={Home} />
                    <PrivateRoute exact path="/assets" component={Assets} />
          </Layout>
        </FluentProvider>
      </AuthContext.Provider>
    );
  }

  updateTheme(themeString) {
    this.setState({
      theme: this.getTheme(themeString),
    });
  }

  getTheme(themeStr) {
    themeStr = themeStr || "";

    switch (themeStr) {
      case "dark":
        return teamsDarkTheme;
      case "contrast":
        return teamsLightTheme;
      default:
        return teamsLightTheme;
    }
  }
}
